<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:body>
            <form action="#" v-if="vmGPathSendMailFormData && Object.keys(vmGPathSendMailFormData).length > 0">
              <div class="form-row">
              <div class="col-md-12 mb-3">
                <label for="validationsession_state">
                  {{cvSendMailUserNameLabel}}</label
                >
                <input
                  v-model="vmGPathSendMailFormData.user_name"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationsession_state">
                  {{cvSendMailUserEmailLabel}}</label
                >
                <input
                  v-model="vmGPathSendMailFormData.user_email"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationsession_state">
                  {{cvSendMailParentEmailLabel}}</label
                >
                <input
                  v-model="vmGPathSendMailFormData.cc_email"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationsession_state">
                  {{cvSendMailEmailModule}}</label
                >
                <select v-model="vmGPathSendMailFormData.subject" name="" id="" class="form-control">{{JSON.stringify(vmGPathSendMailFormData.subject)}}
                  <option :value="subject" v-for="(subject, index) in propSendMailObj.subject" :key="index">{{subject}}</option>
                </select>
              </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="gpath_send_mail()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import ApiResponse from "../Utils/apiResponse"
import { SendMail } from "../FackApi/api/sendMail.js"

export default {
  name: "SendMail",
  props: {
    propSendMailObj: {
      type: Object,
      default: function () {
        return {
          "user_name": "",
          "user_email": "",
          "cc_email": "",
          "subject": {}
        }
      }
    }
  },
  data () {
    return {
      cvSubmitBtn: "Send Mail",
      cvSendMailUserNameLabel: "User",
      cvSendMailUserEmailLabel: "Email",
      cvSendMailParentEmailLabel: "CC Email",
      cvSendMailEmailModule: "Choose Subject",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Send Mail Response",
      vmGPathSendMailFormData: this.initFormData()
    }
  },
  mounted () {
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGPathSendMailFormData) {
          if (!this.vmGPathSendMailFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        user_id: this.propSendMailObj.user_id,
        user_name: this.propSendMailObj.user_name,
        user_email: this.propSendMailObj.user_email,
        cc_email: this.propSendMailObj.cc_email,
        subject: ""
      }
    },
    /**
     * gpath_send_mail
     */
    async gpath_send_mail () {
      try {
        const sendMailResp = await SendMail.sendMailToUser(this, this.vmGPathSendMailFormData)
        if (sendMailResp.resp_status) {
          this.$emit("emitCloseGPathSendMailModal")
        }
        ApiResponse.responseMessageDisplay(this, sendMailResp)
      }
      catch (err) {
        console.error("Exception occurred at gpath_send_mail() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
</style>
